import { defineStore } from "pinia";

export const useProductStore = defineStore("productStore", {
  state: () => ({
    products: [],
    editingProduct: null,
  }),
  actions: {
    addProduct(product) {
      this.products.push(product);
    },
    setEditingProduct(product) {
      this.editingProduct = product;
    },
    clearEditingProduct() {
      this.editingProduct = null;
    },
  },
});
