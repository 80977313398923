<template>
  <v-container fluid fill-height class="d-flex align-start grey lighten-3">
    <v-row class="pa-5 grey lighten-3">
      <v-col cols="12">
        <span class="subtitle secondary--text">Fotografías</span>
      </v-col>
      <v-col cols="12">
        <product-upload-card
          icon="mdi-image-outline"
          text="Da clic para subir la imagen del producto"
        />
      </v-col>
      <v-col cols="12">
        <span class="subtitle secondary--text">Información</span>
      </v-col>
      <v-col cols="12">
        <pat-input
          label="Código de barras"
          placeholder="0000000000"
          v-model="contenidoScan"
        >
          <v-btn icon color="secondary" @click="startScan" slot="append">
            <v-icon class="mt-n1">mdi-camera-outline</v-icon>
          </v-btn>
        </pat-input>
      </v-col>
      <v-col cols="12">
        <pat-input
          label="Nombre del producto"
          placeholder="Ejemplo"
          v-model="product.name"
        />
      </v-col>
      <v-col cols="12" class="">
        <pat-input
          label="Descripción"
          placeholder=""
          v-model="product.description"
          textarea
        />
        <v-checkbox
          v-model="product.requireRecipe"
          label="Requiere receta médica"
        ></v-checkbox>
      </v-col>
      <v-col cols="12" class="mt-n5">
        <pat-select label="Unidad" v-model="product.unit" :items="units" />
      </v-col>
      <v-col cols="12" class="">
        <pat-select
          label="Categoría"
          v-model="product.category"
          :items="categories"
        />
      </v-col>
      <v-col cols="12" class="">
        <pat-input
          label="Precio de compra"
          prefix="$"
          placeholder="0.0"
          v-model="product.purchasePrice"
        />
      </v-col>
      <v-col cols="12" class="">
        <pat-input
          label="Precio de venta"
          prefix="$"
          placeholder="0.0"
          v-model="product.salePrice"
        />
      </v-col>

      <v-col cols="12" v-if="false">
        <v-card>
          <v-card-title class="d-flex justify-space-between">
            <h4>Precios de compra</h4>
            <v-btn icon color="white" class="primary" @click="agregarPrecio"
              ><v-icon>mdi-plus</v-icon></v-btn
            >
          </v-card-title>
          <v-card-text>
            <v-row v-for="(precio, i) in precios" :key="i">
              <v-col cols="10" class="px-5">
                <v-text-field
                  :label="`Precio de venta ${i + 2}`"
                  prepend-inner-icon="mdi-currency-usd"
                  clearable
                  class="white"
                  type="number"
                  v-model="precios[i]"
                  outlined
                  hide-details="auto"
                  dense
                />
              </v-col>
              <v-col cols="2">
                <v-btn icon color="red" @click="quitarPrecio"
                  ><v-icon>mdi-delete</v-icon></v-btn
                >
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" class="">
        <v-btn
          class="rounded-xl"
          color="secondary"
          block
          tile
          @click="saveProduct"
          large
        >
          Guardar</v-btn
        >
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { BarcodeScanner } from "@capacitor-mlkit/barcode-scanning";
import { useProductStore } from "@/stores/productStore";

export default {
  name: "Home",
  data() {
    return {
      product: {
        name: "",
        description: "",
        requireRecipe: false,
        unit: "",
        category: "",
        purchasePrice: 0,
        salePrice: 0,
      },
      units: ["Pieza", "Kilogramo", "Litro", "Metro", "Caja"],
      categories: ["Medicamento", "Alimento", "Higiene", "Limpieza"],
      precios: [],
      name: "",
      contenidoScan: null,
    };
  },
  methods: {
    agregarPrecio() {
      if (this.precios.length < 4) {
        this.precios.push("");
      }
    },
    quitarPrecio(i) {
      this.precios.splice(i, 1);
    },
    async startScan() {
      console.log("Iniciando escaneo de código de barras");
      document
        .getElementById("principal")
        .classList.add("barcode-scanner-active");
      try {
        // eslint-disable-next-line no-unused-vars
        const listener = await BarcodeScanner.addListener(
          "barcodeScanned",
          async (result) => {
            this.contenidoScan = result.barcode.displayValue;
            this.stopScan();
          }
        );

        // Start the barcode scanner
        await BarcodeScanner.startScan();
      } catch (error) {
        console.error("Error al escanear código de barras:", error);
      }
    },
    async stopScan() {
      console.log("Deteniendo escaneo de código de barras");
      document
        .getElementById("principal")
        ?.classList.remove("barcode-scanner-active");
      await BarcodeScanner.removeAllListeners();
      await BarcodeScanner.stopScan();
    },
    saveProduct() {
      const productStore = useProductStore();
      if (this.editingProduct) {
        // Si estamos editando un producto existente, actualizar la información
        Object.assign(this.editingProduct, this.product);
      } else {
        // Si es un nuevo producto, agregarlo a la lista
        productStore.addProduct(this.product);
      }
      this.$router.push("/products");
    },
  },
  computed: {
    editingProduct() {
      const productStore = useProductStore();
      return productStore.editingProduct;
    },
  },
  created() {
    if (this.editingProduct) {
      this.product = { ...this.editingProduct };
    }
  },
};
</script>

<style>
.barcode-scanner-active {
  display: none !important;
}
</style>
